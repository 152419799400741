// COIN_dashboard\client\src\requests\makeRequest.js

import { clearAuthUser } from "../reducers/authUserSlice";
import { store } from "../App";

/**
 * Class to make triggers a request to the server and parse the results (optional)
 * Accepts a function that makes the actual request to the server and returns the Response object
 * Parsing is done based on the content-type of the response and returns an object with the status code and the parsed response
 * If the is not "OK" (200) the response is thrown inside an Error object.
 *
 * If server response with 401 - dispatch to clear user (logged out)
 *
 */
export class Request {
  constructor(requestFunc, params = null, parseResponse = true) {
    this.params = params;
    this.requestFunc = requestFunc;
    this.parseResponse = parseResponse;
    if (!requestFunc) {
      throw Error(" request function is required");
    }
  }

  /**
   * @returns {Promise} Promise that resolves to the parsed response or rejects with an error
   */
  async makeRequest() {
    let res = null;

    try {
      if (this.params) {
        //console.log("Making request with parameters:", this.params); // Log the parameters
        res = await this.requestFunc(this.params);
      } else {
        //console.log("Making request without parameters"); // Log the absence of parameters
        res = await this.requestFunc();
      }

      // Log the response object
      //console.log("Response received:", res);

      // Check if the response is a Response instance
      if (!(res instanceof Response)) {
        //console.warn("Response is not an instance of Response. Returning raw result.");
        return res;
      }

      // Log the response status
      //console.log("Response status:", res.status);

      if (res.status === 401) {
        //console.warn("Unauthorized (401). Dispatching clearAuthUser action.");

        // Logged out
        store.dispatch(clearAuthUser()).finally(() => {
          //console.error("User is logged out. Throwing error.");
          throw Error("Du är utloggad");
        });
      } else if (this.parseResponse) {
        //console.log("Parsing response as parseResponse is true."); // Log parsing decision
        const parsed = await this.parse(res);
        //console.log("Parsed response:", parsed); // Log parsed response
        return parsed;
      } else {
        //console.log("Returning raw response as parseResponse is false."); // Log raw return
        return res;
      }
    } catch (error) {
      console.error("Error occurred in makeRequest:", error); // Log any error that occurs
      throw error; // Re-throw the error for further handling
    }
  }

  /**
   * Returns a promise that resolves to the parsed response or rejects with an error
   * @param {Response} res
   * @returns
   */
  async parse(res) {
    const contentType = res.headers.get("content-type");
    switch (contentType) {
      case "application/json; charset=utf-8":
      case "application/json":
        return this.parseJson(res);
      case "text/csv":
      case "text/csv charset=utf-8":
      case "text/plain; charset=utf-8":
      case "text/plain":
        return this.parseText(res);
      default:
        throw Error("Could not parse server response");
    }
  }

  async parseText(res) {
    if (res.status === 200) {
      let text = await res.text();
      return { statusCode: res.status, text: text };
    } else {
      let text = await res.text();
      throw Error(text);
    }
  }

  async parseJson(res) {
    if (res.status === 200) {
      let json = await res.json();
      return { statusCode: res.status, json: json };
    } else {
      let json = await res.json();
      throw Error(json);
    }
  }
}
